.ql-toolbar{
    // border:none !important;
    // padding: 0 !important;
margin-bottom:10px;
.ql-formats{
    font-size: 20px;
    // display: flex;
// vertical-align: baseline ;
    // padding:2px !important;
    margin-right: 0 !important;
    .ql-formatstyle{
        button{
            border:1px solid #ddd;

        }
      
    }
    .ql-script,.ql-blockquote,
    .ql-link,.ql-image,.ql-video,.ql-formula,.ql-code-block,.ql-clean,.ql-undo,
    .ql-redo,
    .ql-direction,.ql-list,.ql-indent,.ql-picker,.ql-size,.ql-header,.ql-font{ 
        border:1px solid #ddd;
        // span{
           
        // }
        .ql-picker-label{
            padding: 0 !important;
            // svg{
               
            // }
            
        }
    }
   
}
}
.quill{
    border-top:none !important;
    
    .ql-container {
        min-height: 50vh;
    }
}