.config {
    .nav {
        .nav-pills {
            .nav-item {
                .nav-link {
                    color: #000;
                }
            }
        }

        .nav-pills .nav-link {
            color: #000 !important;
        }
    }

    .nav-pills .nav-link.active {
        background: #c4d2e8 !important;
    }
}