.analysis-procedure{
    min-height:100%;
    .icon-btn{
        cursor: pointer;
    }

  .preview{
      text-align: left;
      font-size: 14px;
  }
    .modal{
        .rdw-editor-wrapper{
            // .rdw-editor-toolbar{
            //     // padding:0;
            // }
            .rdw-inline-wrapper{
                padding:0;
                // border:1px solid lightgray;
            .rdw-option-wrapper{
                margin:0;
                padding:4px;
                border-radius:0;
                // border:none;
            }
           
        }
        .rdw-dropdown-wrapper{
            margin:0;
            .rdw-dropdown-selectedtext{
                text-decoration: none;
                color:#000;

            }
        }
        .rdw-list-wrapper{
            .rdw-option-wrapper{
                margin:0;
                padding:5px;
            }
        }
        .rdw-text-align-wrapper{
            .rdw-option-wrapper{
                margin:0;
            }
        }
        .rdw-option-wrapper{
            margin:0;
        }
       
        }
    }
    // .modal{
    //     max-width: 600px;
    // }

    .summary-finding
    {
        text-align: left;
        font-weight: 500;
        line-height: 1;
        p {
            font-size: 14px !important;
        }
    }
}