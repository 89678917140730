.nav-tabs .nav-item .nav-link {
    // color: #fff !important;
    border: 0px !important;
    outline: 0px !important;
    background: #dddbdb;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: #5a53b2 !important;
    color: #fff !important;
    // border-top: 0px !important;
    // border-left: 0px !important;
    // border-right: 0px !important;
    // background: #ebebeb;
    // border-bottom: 4px solid #ebebeb !important;
}