.lifestyle {
    background: #f5f6fb;
    .heading {
        h5 {
            color: #252525;
        }
    }

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .react-bootstrap-table{
        .table{
            tbody{
                tr{
                    td{
                        .col{
                            padding-left: 0.49rem;
                            i{
                                cursor:pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    
}
