.tab-content {
    background: #fff;
    border-radius: 10px;
  
    .arrow-up {
        width: 40px;
        height: 40px;
        background: #fff;
        transform: rotate(45deg);
        margin-top: -25px;
        margin-left: 35%;
    }
    .associate-doc {
        .add-btn {
            width: 130px;
            background: #1f98df;
            color: #fff;
            border-radius: 4px;
            font-size: 14px;
            align-items: center;
            .plus-icon {
                font-size: 17px;
                font-weight: bold;
            }
        }
        .divider {
            width: 98.5%;
            height: 2px;
            border: 1px solid gray;
        }
        .table-data {
            background: none;
            border-radius: 10px;

            .search-label {
                .sr-only {
                    display: none;
                }
            }

            input {
                width: 300px;
                font-size: 12px;
                border: none;
                outline: none;
                text-indent: 14px;
                font-size: 12px;
                border-radius: 20px;
                line-height: 1.375rem;
            }
            #search-icon {
                font-size: 0.657rem;
                position: absolute;
                text-align: center;
                line-height: 2.375rem;
            }
            .react-bootstrap-table {
                padding: 0;
                margin: 0;
                border-radius: 10px;
                background-color: #fff;
                .table {
                    padding: 0;
                    margin: 0;
                    background: #fff;
                    border-radius: 10px;
                    table-layout: auto;
                    overflow-x: auto;
                    thead {
                        tr {
                            background: #edf4ff;
                            outline: none;
                            border: none;
                            font-size: 14px;
                            font-family: "Lato", sans-serif;
                            color: #162d57;
                            th {
                                border-color: transparent;
                                text-align: left;
                                padding-left: 12px;
                                white-space: nowrap;
                            }
                        }
                        tr:first-child th:last-child {
                            border-top-right-radius: 10px;
                        }
                        tr:first-child th:first-child {
                            border-top-left-radius: 10px;
                        }
                    }
                    tbody {
                        border-top: none;
                        tr {
                            background: #f3f3f4;
                            color: #596883;
                            font-size: 12px;
                            text-align: left;
                            align-items: center;
                            border-top: 3px solid #fff;
                            border-bottom: none;
                            cursor: pointer;
                            td {
                                align-items: center;
                                padding: 10px 10px;
                                overflow-wrap: break-word;
                                white-space: normal !important;
                                margin-bottom: 0;
                                border: none;
                                .action-icons {
                                    .edit-icon,.view-icon,
                                    .del-icon ,.download-icon{
                                        color: #fff;
                                        padding: 5px;
                                        border-radius: 5px;
                                        margin: 3px;
                                    }
                                    .download-icon{
                                        background: #8199bf;
                                    }
                                    .edit-icon {
                                        background: #3f9b58;
                                    }
                                    .del-icon {
                                        background: #f44336;
                                    }
                                }
                            }
                            .react-bs-table-no-data {
                                color: gray;
                            }
                        }

                        tr:last-child td:first-child {
                            border-bottom-right-radius: 10px;
                        }
                        tr:last-child td:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
            }
            .react-bootstrap-table-pagination {
                justify-content: flex-end;
                align-items: center;
                flex-direction: row-reverse;
                .col-md-6 {
                    max-width: 80px;
                    padding: 0;
                    margin: 0 5px;
                    align-items: center;
                    .react-bs-table-sizePerPage-dropdown {
                        .btn {
                            text-align: center;
                            width: 100%;
                            background: transparent;
                            border: 1px solid lightgray;
                            color: #333;
                            // padding:5px 10px;
                        }

                        .dropdown-menu {
                            margin: 10px;
                            min-width: 50px !important;
                        }
                    }
                }
                .react-bootstrap-table-pagination-list {
                    margin: 10px 0 10px auto;
                    align-items: center;
                    padding: 0;
                    .react-bootstrap-table-page-btns-ul {
                        margin-bottom: 0;
                        .page-item {
                            outline: none;

                            .page-link {
                                margin-left: 2px;
                                border-radius: 5px;
                                font-size: 14px;
                                outline: none;
                                color: #252525;
                                background: transparent;
                            }
                        }
                        .page-item.active {
                            .page-link {
                                background: #1f98df;
                                outline: none;
                                color: #fff;
                                border: 1px solid #1f98df;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal {
        .modal-content {
            border-radius: 20px;
            .modal-header {
                border-bottom:none;
                .downdoc-title{
                    color:#5a53b2;
                   
                }
                .modal-title {
                    font-size: 18px;
                    // font-weight: bold;
                    // color: #212121;
                }
                .btn-close {
                    font-size: 10px;
                    font-weight: bold;
                    color: #292929;
                }
            }
            .modal-body {
                table{
                   width: 70%;
                        tr{
                            background: #fff;
                            border:none;
                            th{
                                border:1px solid lightgray;
                            }
                            td{
                              font-size: 13px;
                              border:1px solid lightgray;
                            }
                            .doc-name{
                                word-wrap:break-word;
                                white-space: normal;
                            }
                        }
                }
                select {
                    background-color: #f3f3f4;
                }
                .file-upload {
                    height: 100px;
                    background: #f3f3f4;
                    .sc-bczRLJ {
                        width: 100%;
                        height: 100%;
                    }
                }
                .upload-box {
                    display: none;
                }
                .upload-box,
                .uploadBox {
                    .MuiDropzoneArea-root {
                        padding: 0;
                        margin-top: 2px;
                        background-color: #eceeef;
                        border: none;
                        .MuiDropzoneArea-icon {
                            display: none;
                        }
                        .MuiDropzoneArea-textContainer {
                            align-self: center;
                            .MuiDropzoneArea-text {
                                margin-top: 40px;
                                align-items: center;
                                font-size: 14px;
                            }
                        }
                        .MuiDropzonePreviewList-imageContainer {
                            .MuiDropzonePreviewList-image {
                                width: 70px;
                                height: 40px;
                                path {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                            img {
                                width: 80px;
                                height: 50px;
                            }
                            p {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .upload-box.showBox {
                    display: block;
                }
                .btn-save{
                    background: #1f98df;
                }
                .btn-cancel{
                    background:#ff4e42 ;
                }
            }
            .modal-footer {
                border: none;
                .btn-save {
                    background: #1f98df;
                }
                .btn-cancel {
                    background: #ff4e42;
                }
            }
        }
    }
}

.hand{
cursor: pointer;
}



@import "../../responsive.scss";
@include xl {
    .tab-content {
        .associate-doc {
            .table-data {
                .react-bootstrap-table {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .action-icons {
                                        display: flex;
                                        flex-direction: row;

                                        .edit-icon {
                                            margin-right: 2px;
                                            
                                        }
                                        .download-icon{
                                            background: #8199bf;
                                        }
                                        .edit-icon,
                                        .del-icon,.download-icon {
                                            //    margin:2px;
                                            padding: 5px;
                                            // background:#162d57;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include lg {
    .tab-content {
        .associate-doc {
            .table-data {
                .react-bootstrap-table {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .action-icons {
                                        display: flex;
                                        flex-direction: row;

                                        .edit-icon {
                                            margin-right: 2px;
                                        }
                                        .edit-icon,
                                        .del-icon {
                                            //    margin:2px;
                                            padding: 5px;
                                            // background:#162d57;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include md {
    .tab-content {
        .associate-doc {
            .table-data {
                .react-bootstrap-table {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .action-icons {
                                        display: flex;
                                        flex-direction: row;
                                        // .edit-icon{
                                        //     margin-bottom:2px;
                                        // }
                                        .edit-icon,
                                        .del-icon {
                                            //    margin:2px;
                                            padding: 3px;
                                            // background:#162d57;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include tab {
    .tab-content {
        .associate-doc {
            .table-data {
                .react-bootstrap-table {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .action-icons {
                                        display: flex;
                                        flex-direction: row;
                                        // .edit-icon{
                                        //     margin-bottom:2px;
                                        // }
                                        .edit-icon,
                                        .del-icon {
                                            //    margin:0;
                                            padding: 3px;
                                            // background:#162d57;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include mob-ver {
    .tab-content {
        .associate-doc {
            .table-data {
                .react-bootstrap-table {
                    .table {
                        tbody {
                            tr {
                                td {
                                    .action-icons {
                                        display: flex;
                                        flex-direction: row;
                                        // .edit-icon{
                                        //     margin-bottom:2px;
                                        // }
                                        .edit-icon,
                                        .del-icon {
                                            margin: 0;
                                            padding: 2px;
                                            // background:#162d57;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
