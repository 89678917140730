.rec-tab-row {
    min-height: 100% !important;
    height: 600px;

    .recording-analysis {
        height: 100% !important;
        background: none;

        .content {
            height: 100% !important;
            background: #fff;

            .tab-bodycontent {

                .nav-item {

                    a {
                        border-radius: 0;
                    }
                }

                .tab-content {
                    height: 80%;
                }
            }
        }

        .arrow {
            width: 40px;
            margin-left: 88%;
            z-index: -10 !important;
        }

        form {
            z-index: 1;

            .btn {
                font-family: "Lato", "sans-serif";
            }

        }

        .nav-pills {
            .nav-item {
                cursor: pointer;

                .nav-link {
                    font-size: 15px;
                    color: #000;
                }

            }

            .nav-item {
                .nav-link.active {
                    color: 'black';
                    font-weight: bolder;
                    background: #e0eef8;
                }

            }
        }

        .patient-demo {
            h6 {
                font-weight: bold;
            }
        }

    }

    .user-information {
        max-height: 100%;

        // max-height: 96.5% !important;
        .pnt-card {
            height: 100%;

            .img-container {
                background: #e0f3f9;
            }

            label {
                font-size: 14px;
            }

            .ass-tag {
                .checkbox-container {
                    border: none;
                    max-height: 100px;
                    //  max-height: 7%;
                    overflow-y: auto;
                   
                    overflow-x: hidden;

                    .tag {
                        font-size: 12px;
                        max-width: 170px;
                        white-space: no-wrap;
                        word-break: break-word;
                        height: auto;
                        color: #000 !important;
                        background: #fbfbfb;
                        border: 1px solid lightgray;
                        cursor: pointer;

                        .remove-tag {
                            cursor: pointer;
                        }
                    }
                }
            }

            .ass-document {
                h6 {
                    font-size: 15px;
                    font-weight: 500;
                }

                .card {
                    background: #e8e8e8;
                    border: none;

                    a {
                        font-size: 13px;
                        color: #5a53b2;
                        text-decoration: underline;
                    }
                }

            }

            .ass-tag {
                min-height: 5%;
                overflow-y: auto;
                display: inline;

                .add-icon {
                    cursor: pointer;
                }

                h6 {
                    font-size: 15px;
                    font-weight: 500;
                }

                .tag-name {
                    background: #fbfbfb;
                    border: 1px solid lightgray;
                    border-radius: 5px;
                }
            }

            .modal {
                .modal-body {
                    .css-b62m3t-container {
                        .css-g1d714-ValueContainer {
                            overflow-y: scroll;
                            max-height: 100px;

                            .css-1rhbuit-multiValue {
                                border-radius: 10px;
                                padding: 2px 5px;
                                background: #1f98df;
                                color: #fff;

                                .css-12jo7m5 {
                                    color: #fff;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-bg {
        height: 55px;
    }
}

@import '../../responsive.scss';

@include xl{
    .rec-tab-row{
        height:650px;
    }
}