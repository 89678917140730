.diagnosis-tab{
    background: #f5f5f5;
    
    .add-icon{
        cursor: pointer;
    }
    .container{
        border:1px solid lightgray;
        // max-height: 80vh;
        background: #fff;
        height:400px;
        overflow-y: auto;
        overflow-x: hidden;
        // border-bottom: 5px;
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;
            .medic-list{
                list-style-type: disc;
            }
            li{
                align-items: center;  
                // background: #fff2f3; 
                border-radius: 2px;   
                color: #6b6869; 
                border:1px solid #f2f2f2;  
                list-style: none;
                margin: 5px;
                padding: 5px;
                button{
        
                    align-items: center;
                    color: #6b6869; 
                    background: none;
                    border: none;
                    cursor: pointer;
                    height: 15px;
                    width: 15px;
                    font-size: 12px;
                    border-radius: 50%;
                
                    display: inline-flex;
                
                    justify-content: center;
                
                    margin-left: 8px;
                
                    transform: rotate(45deg);
                
                  }
        
            
              }
            }
        
    }
    .checkbox-container{
        border:none;
        .tag{
            font-size: 12px;
            max-width: 170px;
          
            word-break: break-word;
            height:auto;
            background: #fbfbfb;
            border:1px solid lightgray;
            .remove-tag{
                cursor: pointer;
            }
        }
       
        .check-btn{
            outline:none;
            border:none;
            height:30px;
            .btn{
                font-size: 13px;
                width:95px;
                background: #635757;
                border:1px solid #f3f3f3;
            }
          
        .btn-check:focus+.btn{
            // background: #ffe3e5;
            outline:none;
            font-weight: bold;
            box-shadow: none;
        }
        .btn-check:checked+.btn{
            background: #ffe3e5;
            font-weight: bold;
        }
        .btn-check+.btn{
            background: #fbfbfb;
        }
        
       
    }
        .btn.focus{
            outline:none;
        }
    }
    .modal{
        .modal-body{
            .css-b62m3t-container{
                
                .css-g1d714-ValueContainer{
                overflow-y: auto;
                max-height: 100px;
                .css-1rhbuit-multiValue{
                    border-radius:10px;
                    padding: 2px 5px;
                    background: #1f98df;
                    color:#fff;
                    
                    .css-12jo7m5{
                        color:#fff;
                        font-size: 14px;
                    }
                }
                }
            }
            // .css-g1d714-ValueContainer{
            //     height:80px;
            // }
        }
    }
    
}