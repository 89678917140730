.user-information {
    max-height: 94%;

    .pnt-card {
        height: 99%;

        .img-container {
            background: #e0f3f9;
        }

        label {
            font-size: 14px;
        }

        .checkbox-container {
            border: none;
            max-height: 105px;
            overflow-y: auto;
            overflow-x: hidden;

            .tag {
                font-size: 12px;
                max-width: 170px;
                white-space: no-wrap;
                word-break: break-word;
                height: auto;
                background: #fbfbfb;
                border: 1px solid lightgray;
                cursor: pointer;

                .remove-tag {
                    cursor: pointer;
                }
            }
        }

        .ass-document {
            h6 {
                font-size: 15px;
                font-weight: 500;
            }

            .card {
                background: #e8e8e8;
                border: none;

                a {
                    font-size: 13px;
                    color: #5a53b2;
                    text-decoration: underline;
                }
            }

        }

        .ass-tag {
            min-height: 10%;
            overflow-y: auto;

            .add-icon {
                cursor: pointer;
            }

            h6 {
                font-size: 15px;
                font-weight: 500;
            }

            .tag-name {
                background: #fbfbfb;
                border: 1px solid lightgray;
                border-radius: 5px;
            }
        }

        .modal {
            .modal-body {
                .css-b62m3t-container {
                    .css-g1d714-ValueContainer {
                        overflow-y: scroll;
                        max-height: 100px;

                        .css-1rhbuit-multiValue {
                            border-radius: 10px;
                            padding: 2px 5px;
                            background: #1f98df;
                            color: #fff;

                            .css-12jo7m5 {
                                color: #fff;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import '../../responsive.scss';

@include md {
    .user-information {
        max-height: 100% !important;
        .pnt-card{
            height: 100% !important;
        }
    }

}