.data-calc{
    background-color: #edf4ff;
    .css-1s2u09g-control {
        padding: 0;
    }
    
    .css-1hb7zxy-IndicatorsContainer {
        border: none;
    }
    
    .search-wrapper {
        .chip {
            // display:block;
            word-wrap:break-word;
            white-space: normal;
           
        }
    
        .searchBox {
            border: none;
        }
    }
    .css-1okebmr-indicatorSeparator {
        border: none;
        background: none;
    }

}

.interpretForm {
    background: #f5f5f5;
    max-height: 605px;
    overflow-y: auto;
  
    form {
        input, .form-select {
            font-size: 12px;
            border: 1px solid #eee;
            background-color: #f4f7fa;
            border-radius: 4px;
        }

        .title {
            font-size: 13px;
            font-weight: bold;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type="radio"]{
            background-color:lightgray;
        }
        input[type="radio"]:checked{
            background-color: #1f98df;
        }
        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        label {
            font-size: 12px;
        }

        span {
            font-size: 12px;
        }

        // input[type='radio']:after {
        //     width: 15px;
        //     height: 15px;
        //     border-radius: 15px;
        //     top: -1px;
        //     left: -1px;
        //     position: relative;
        //     background-color: #d1d3d1;
        //     content: '';
        //     display: inline-block;
        //     visibility: visible;
        //     border: 2px solid white;
        // }

        // input[type='radio']:checked:after {
        //     width: 15px;
        //     height: 15px;
        //     border-radius: 15px;
        //     top: -1px;
        //     left: -1px;
        //     position: relative;
        //     background-color: #1f98df;
        //     content: '';
        //     display: inline-block;
        //     visibility: visible;
        //     border: 2px solid white;
        // }

        .int-content {
            background: #fff;

            .css-1s2u09g-control {
                padding: 0;
            }

            .css-1okebmr-indicatorSeparator {
                border: none;
                background: none;
            }

            .css-1hb7zxy-IndicatorsContainer {
                border: none;
            }

            .css-1s2u09g-control {
                font-size: 12px;
                border: 1px solid #eee;
                background-color: #f4f7fa;
                border-radius: 4px;
            }
        }
    }
}

.posterior-content {
    background: #cecece;
    margin-left: 5px;

    height: 605px;

    .posterior-cont {
        height: 450px;
        overflow-y: auto;

        .title {
            font-size: 13px;
            font-weight: bold;
        }

        input[type="number"] {
            font-size: 12px;
            border: 1px solid #eee;
            background-color: #f4f7fa;
            border-radius: 4px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        span {
            font-size: 12px;
            text-align: right !important;
            margin-left: 4%;
        }

        .form-check-label {
            font-size: 12px;
        }

        // .footer{margin: auto;
        //     // position: fixed;
        //     // left: 0;
        //     // bottom: 0;
        //     // right: 0;
        //     // backgroundColor: "green"
        // }
       
    }
}
.btn-addMark {
    border: 1px solid #1f98df;
    color: #1f98df;
    font-size: 12px;
    background: transparent;
}
.btn-saveInt {
    background: #1f98df !important;
}
.btn-saveInt:hover{
    background: #1f98df;
}
.btn-saveInt.active{
    background: #1f98df;
}