.account {
    background: #f5f6fb;

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    #search-icon{
        font-size: 12px !important;
        line-height: 2rem !important;
    }
    select{
        width: 20%;
        
    }
    .form-select{
        border:1px solid#1f98df;
    }
    
   
    .react-bootstrap-table{
        .table{
            thead{
                tr{
                    th{
                        white-space: nowrap;                    }
                }
            }
            tbody{
                tr{
                    cursor: pointer;
                    td{
                        white-space: 'normal'; word-wrap: 'break-word';
                        p{
                            margin-bottom: 0;
                        }
                        .spinner{
                            height: 50vh;
                            .css-1xdhyk6{
                                margin-top:10%;
                            }
                        }
                    }
                }
            }
        }
    }

}

@import "../responsive.scss";

@include mob-hor-max {
    .topic {
        .btn {
            margin-right: 12px;
        }
    }
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    .col {
                        i {
                            margin-right: 1px !important ;
                        }
                    }
                }
            }
        }
    }
}

@include tab {
    .react-bootstrap-table {
        .table {
            tr {
                td {
                    .col {
                        i {
                            margin-right: 1px !important;
                        }
                    }
                }
            }
        }
    }
}
