.template-preview {
    background: #f5f5f5;
    height: 96%;

    .btn-preview {
        background: #1f98df;
    }

    .css-1s2u09g-control {
        padding: 0;
    }

    .css-1okebmr-indicatorSeparator {
        border: none;
        background: none;
    }

    .css-1hb7zxy-IndicatorsContainer {
        border: none;
    }

    .css-1s2u09g-control {
        font-size: 12px;
        border: 1px solid #eee;
        background-color: #fff;
        border-radius: 4px;
    }

    

    .preview-template {
        display: inline;
        height: 27vw;
        overflow-y: auto;
        overflow-x: hidden;

        .template-header {
            p {
                font-weight: 400;
                color: #000;

                .web-link {
                    color: #1f98df;
                    text-decoration: underline;
                }
            }

            .template-title {
                font-weight: bolder;
                font-size: .7vw;
            }
        }

        .template-body {
            .patient-information {
                p {
                    color: #000;
                    font-weight: 500;
                    font-size: .7vw;
                }

            }

            .findings {
                .html-text {
                    p{
                    color: #000;
                    font-size: .8vw !important;
                    font-weight: 500;
                    }
                }

                .edit-txt {
                    cursor: pointer;
                }

                .summary-finding {

                    font-weight: 500;

                    p {
                        color: #000;
                        font-size: .7vw !important;
                    }

                   
                }

                p {
                    font-size: .7vw;
                }

                .quill {
                    .ql-container {
                        min-height: 130px !important;

                        .ql-editor {
                            // height:100px !important;
                            overflow: auto;
                        }

                    }
                }

                .btn-save {
                    background: #5a53b2;
                }
            }

            h6 {
                font-weight: bolder;
                font-size: .8vw;
            }
        }
        .template-page1{
            table{
                td{
                    .btn-save{
                        background: #1f98df;
                    }
                    .edit-txt{
                        cursor: pointer;
                    }
                    .quill {
                        .ql-container {
                            min-height: 130px !important;
    
                            .ql-editor {
                                // height:100px !important;
                                overflow: auto;
                            }
    
                        }
                    }
                }
            }
        }
    }

    .bottom-content {
        display: inline-block;

        p {
            font-size: 14px;
            font-weight: 500;
        }

        button {
            font-size: small;
            background: #1f98df;
        }

        select {
            font-size: 14px;
        }
    }

    .modal {
        .modal-body {
            .text-editor {
                .options {
                    // background: none;
                    border-bottom: 1px solid lightgray;

                    .option-button {
                        background: none;
                        background-color: none;

                    }

                    .input-wapper {
                        .adv-option-button {
                            width: 4px;
                        }
                    }

                }

                .editor-content {
                    height: 800px;
                    font-size: 14px;
                    border: 1px solid #333;

                    .non-editable {
                        background: #000;
                    }
                }


            }
        }
    }
}
@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    img{
        margin-left: 25px !important;
    }
    h3{
        margin-top: 30px !important;
    }
    .eeg-title{
        margin-left: 15px !important;
    }
    div.page-footer {
        display: none;
    }
    .sign-img{
        margin-left:0 !important;
    }
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
  
}

@page {
    size: auto;
    margin: 10mm;
}