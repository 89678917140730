.pipeline{
    .tabs{   
            div{
                .step-counter{
                    a{
                        font-family: 'Lato',sans-serif;
                        font-weight: bold !important;
    
                        }
                  
                div{
                   
                    // margin-bottom:10px;
                    div{
                        background:#fff;
                        border-radius: 10px;
                        font-weight: 400 !important;
                        font-family: 'Lato',sans-serif;
                        div{
                            cursor: pointer;
                            span,a{
                               line-height: 30px !important;
                               
                            }
                           
                           
                        }
                        a{
                            margin-bottom: 10px;
                        }

                    }
                }
            }
            }
            
            .btn-bg{
                background: transparent;
                margin-bottom:10px;
                .btn-back{
                    background: #1f98df;
                    cursor: pointer;
                    color:#fff;
                }
                .btn-next{
                    background: none;
                    border:1px solid #1f98df;
                    cursor: pointer;
                }
                .btn-next:hover{
                    background: #1f98df;
                    color:#fff;

                }
                .btn-back:hover{
                    background: none;
                    border: 1px solid #1f98df;
                    color:#1f98df;
                }
                // .btn-skip{
                //     background: #c04444;
                //     color:#fff;
                // }
            }
        
    }
}
