$primary : #7A6EFE;

$secondary :  #FFA902;

$bcolor : #2F80ED;

$focus : #5A53B2;

$success : #41BC21;

$danger : #C20016;

$txtcolor: #000;

$accordion-icon-active-color: #ffffff;

// @import "bootstrap/variables";
@import '~bootstrap/scss/bootstrap.scss';