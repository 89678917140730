.patient-list {
    background: #f5f6fb;
#search-icon{
    font-size: 12px !important;
    line-height: 2rem !important;
}
    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .btn-export{
        border-radius: 20px;
        background: #1f98df;
        font-size: 13px;
    }
    .react-datepicker__input-container {
        font-size: 12px;
        border: 1px solid #eee;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        //  height: 20px;
        input{
            background: transparent;
        }
    }
    
    .react-datepicker__month-select,
    .react-datepicker__year-select {
        margin-top: -10px;
        background-color: #f0f0f0;
    }
    .react-datepicker__month {
        .react-datepicker__week {
            .react-datepicker__day--outside-month {
                visibility: hidden;
            }
        }
    }
    .react-datepicker__current-month {
        display: none;
    }
    .react-datepicker__year-read-view--down-arrow {
        display: none;
    }
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    cursor: pointer;

                    .col {
                        i {
                            padding: 2px;
                            border-radius: 4px;
                            margin-right: 3px;
                            cursor: pointer;
                        }
                    }

                    td {
                        .action-icons{
                           i{
                            height:22px;
                            color:#fff;
                            border-radius: 5px;
                           }
                           .view-icon{
                            background: #3f9b58;
                           }
                           .del-icon{
                            background: #f44336;
                           }
                        }
                        .spinner {
                            height: 50vh;

                            .css-1xdhyk6 {
                                margin-top: 10%;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal {
        .modal-body {
            .tag-container {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid #eee;
                border-radius: 5px;
                padding: 2px;
                background-color: #f4f7fa;

                input {
                    border: none !important;
                    flex: 1;
                    outline: none;
                    padding: 5px;
                    color: #000;
                }
            }

            .tag {
                display: flex;
                align-items: center;
                padding: 5px;
                // border: 1px solid #ced4da;
                border-radius: 5px;
                height: 25px;
                margin: 2px 5px 2px 0px;
                color: #444545;
                background: #fff2f3;
            }

        }
    }

    .modal-content .modal-body select,
    .modal .modal-content .modal-body .react-datepicker__input-container {
        // background: none ;
        border-radius: none;
        font-size: 14px;

        input {
            border: none;
        }
    }

    .search-wrapper {
        .chip {
            // display:block;
            word-wrap:break-word;
            white-space: normal;
        }
    }

    .modal-content .modal-body select,
    .modal .modal-content .modal-body {

        .react-datepicker__month-select,
        .react-datepicker__year-select {
            margin-top: -10px;
            background-color: #f0f0f0;
        }
    }

    .react-datepicker__month {
        .react-datepicker__week {
            .react-datepicker__day--outside-month {
                visibility: hidden;
            }
        }
    }

    .react-datepicker__current-month {
        display: none;
    }
}

@import "../responsive.scss";

@include xl{
    .patient-list {
        .react-bootstrap-table {
            .table {
                tbody {
                    tr {
                        td {
                            .action-icons {
                                display: flex;
                                flex-direction: row;

                                .view-icon {
                                    margin-right: 2px;
                                    margin-bottom:1px;
                                }

                                .view-icon,
                                .del-icon {
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}

@include lg {
    .patient-list {
        .react-bootstrap-table {
            .table {
                tbody {
                    tr {
                        td {
                            .action-icons {
                                display: flex;
                                flex-direction: row;

                                .view-icon {
                                    margin-right: 2px;
                                }

                                .view-icon,
                                .del-icon {
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include md {
    .patient-list {
        .react-bootstrap-table {
            .table {
                tbody {
                    tr {
                        td {
                            .action-icons {
                                display: flex;
                                flex-direction: row;

                                .view-icon,
                                .del-icon {
                                    padding: 3px;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include tab {
    .patient-list {
        .react-bootstrap-table {
            .table {
                tbody {
                    tr {
                        td {
                            .action-icons {
                                display: flex;
                                flex-direction: row;

                                .view-icon,
                                .del-icon {
                                    padding: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mob-ver {
    .patient-list {
        .react-bootstrap-table {
            .table {
                tbody {
                    tr {
                        td {
                            .action-icons {
                                display: flex;
                                flex-direction: row;

                                .view-icon,
                                .del-icon {
                                    margin: 0;
                                    padding: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}