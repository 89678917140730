.table-data {
    background: none;
    border-radius: 10px;

    .search-label {
        .sr-only {
            display: none;
        }
    }

    input {
        width: 300px;
        font-size: 12px;
        border: none;
        outline: none;
        // height: 40px;
        // max-width: 300px;
        text-indent: 14px;
        font-size: 12px;
        border-radius: 20px;
        line-height: 1.375rem;
        // position: relative;
    }

    #search-icon {
        font-size: 0.657rem;
        position: absolute;
        text-align: center;
        line-height: 2.375rem;

    }

    .react-bootstrap-table {
        border-radius: 10px;
        background-color: #fff;

        .table {
            margin-bottom: 0;

            .order {
                margin-left: 5px;
                // flex-direction: row;
            }

            .react-bootstrap-table-sort-order {

                margin-left: 3px;
            }

            .react-bootstrap-table-sort-order.dropup {
                margin-left: 5px;
            }
        }

        .table thead .dropdown {
            margin-left: 2px;
            margin-right: -8px;
        }

        .table thead .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-top: 4px solid \9;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
        }

        .table thead .dropup .caret {
            margin-top: 1px;
        }

        .table thead .dropdown .caret {
            margin-bottom: 3px;

            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);


        }

        // .table thead .react-bootstrap-table-sort-order{
        //     // margin-left:8px;
        // }

        .table thead .dropup .caret,
        table thead .dropdown .caret {
            padding: 0;
            margin-left: 0;
            margin-right: 0;
            border-top: 0;
            border-bottom: 4px dashed;
            border-bottom: 4px solid \9;
            content: "";
        }

        .table {
            background: #fff;
            border-radius: 10px;
            table-layout: auto;
            overflow-x: auto;

            thead {
                tr {
                    outline: none;
                    border: none;
                    background: #e1e1e1;
                    font-size: 15px;
                    color: #162d57;

                    th {
                        white-space: nowrap;
                        border-color: transparent;
                        text-align: left;
                        padding-left: 12px;
                        padding-right: 0;
                        font-family: "Lato", "sans-serif";
                    }
                }

                tr:first-child th:last-child {
                    border-top-right-radius: 10px;
                }

                tr:first-child th:first-child {
                    border-top-left-radius: 10px;
                }
            }

            tbody {
                border-top: none;

                tr {

                    color: #596883;
                    font-size: 14px;
                    text-align: left;
                    border: 1px solid #f4f4f4;

                    td {
                        font-family: "Lato", "sans-serif";
                        vertical-align: middle;
                        padding: 0.8rem;
                        border: none;
                        text-transform: capitalize;

                        p {
                            font-size: 13px;
                            font-family: "Lato", sans-serif;
                            text-transform: capitalize;
                        }

                        .tags {
                            background: #cbe3f9;
                            color: #55595d;
                            border-radius: 5px;
                        }
                    }

                }

                tr:last-child td:first-child {
                    border-bottom-right-radius: 10px;
                }

                tr:last-child td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .react-bootstrap-table-pagination {
        justify-content: flex-end;
        align-items: center;
        flex-direction: row-reverse;
        margin: 20px 0;

        .col-md-6 {
            max-width: 80px;
            padding: 0;
            margin: 0 0 0 5px;
            align-items: center;

            .react-bs-table-sizePerPage-dropdown {
                height: 20px;

                .btn {
                    line-height: 15px;
                    text-align: center;
                    width: 100%;
                    background: #fff;
                    border: 1px solid lightgray;
                    color: #333;
                }

                .dropdown-menu {
                    margin: 10px;
                    min-width: 50px !important;

                    .dropdown-item {

                        a {
                            font-family: "Lato", sans-serif;
                            text-decoration: none;
                            color: #252525;
                        }

                    }
                }
            }
        }

        .react-bootstrap-table-pagination-list {
            margin: 10px 0 10px auto;
            align-items: center;
            padding: 0;

            .react-bootstrap-table-page-btns-ul {
                margin-bottom: 0;
                line-height: 15px;

                .page-item {
                    outline: none;

                    .page-link {
                        margin-left: 2px;
                        border-radius: 5px;
                        font-size: 14px;
                        outline: none;
                        font-family: "Lato", sans-serif;
                        color: #252525;
                        background: #fff;
                    }
                }

                .page-item.active {
                    .page-link {
                        background: #1f98df;
                        outline: none;
                        color: #fff;
                        font-family: "Lato", sans-serif;
                        border: 1px solid #1f98df;
                    }
                }
            }
        }
    }
}

.ant-steps-item {
    .ant-steps-item-container {
        display: flex;
        flex-direction: row;
    }
}

.ant-steps-item-icon {
    margin: 0px !important;

    .ant-steps-item-tail::after {
        // ::after{
        color: pink !important;
        // }
    }

    .ant-steps-icon {
        color: #5754a3 !important;
    }
}

.edf-process {
    height: 95% !important;
}

.edf-container {
    height: 22vh;
    overflow-y: auto;
    overflow-x: none;
}

.nav-tabs {
    --bs-nav-tabs-border-color: #ebebeb !important;
    --bs-nav-tabs-border-width: 4px !important;
}

nav .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background: #ebebeb !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    // font-weight: bold;
    background: #ebebeb;
    border-bottom: 4px solid #ebebeb !important;

}

.nav-tabs .nav-item .nav-link {
    color: #000 !important;
    border: 0px !important;
    outline: 0px !important;
}

.modal .modal-dialog.test {
    max-width: 1200px;
}

//   .ant-steps-item-active{
//     .ant-steps-item-container{
//         .ant-steps-item-icon{
//             .ant-steps-icon{
//                 color: #5754a3;
//               }
//         }
//     }#9fc1e2
//   }
//   .ant-steps-item-process .ant-steps-item-icon { background: #5754a3;}
.table {
    table {

        thead {
            tr {
                background: #dde7ec !important;
                border: 0;

            }
        }

        tbody {
            tr {
                border: 1px solid lightgray;

                .Completed {
                    // font-weight: bold;
                    text-align: center;
                    background: #e2efd9;
                }

                .In.Progress {
                    // font-weight: bold;
                    text-align: center;
                    background: #fcdc5e;
                }

                .Yet.to.Start {
                    // font-weight: bold;
                    text-align: center;
                    background: #eaeeee;
                }
            }

            .heading {
                background: #9fc1e2;
                font-weight: bolder;
            }
        }
    }
}

.edf-link {
    cursor: pointer;
}

.progress-b {
    background-color: #ffd966;
    border: 4px solid #f4d063;
    width: 180px;
    margin: 5px auto;
    border-radius: 50%;
    color: white;
    font-size: 1.6em;

    h6 {
        text-align: center;
        margin: 0;
        padding: 11% 0;
    }
}

.complete {
    background: #dbf4db;
    color: green;
    text-transform: capitalize;
}

.inprogress {
    background: #ffc14e42;
    color: #eb9903;
    text-transform: capitalize;
}

@import '../responsive.scss';

@include mob-hor-max {
    .search {
        flex-direction: column;
    }

    .btn-create {
        width: 100px;
        margin-right: 0;
    }

    .searchbox {
        margin-left: 70px;
    }


}

@include mob-ver {
    .searchbox {
        width: 10%;
    }
}

// @include mob-hor-min{
//     .searchbox{
//         width: 100px;
//         margin-left:0;
//     }
// }
.gray-bg {
    background: #f9f9f9;
    height: 450px;
}