.btn-close{
  cursor: pointer;
}
.btn-tag:hover {
  border:none;
  outline:none;
}
.btn-tag:focus{
  border: none;
  outline:none;
}
.tag-box {
  min-width: 100%;
    max-width: 75%;
    min-height: 150px;
    border: 1px solid #f1f1f1;
    background: #fff;
  .tag-container {
    
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 10px;
    input{
      background: transparent !important;
    border: none;
    }
  }
  
  .tag {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    height: 25px;
    margin: 2px 5px 2px 0px;
    color: #444545 !important;
    background: #fff2f3;
  }
  input {
    border: none;
    flex: 1;
    outline: none;
    padding: 10px;
    // background-color: #eee;
    // background: #000;
    color: #000;
  }
  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .tag span {
    margin-left: 5px;
    cursor: pointer;
  }
 
}
.tag {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  height: 25px;
  margin: 2px 5px 2px 0px;
  color: #444545 !important;
  background: #fff2f3;
}
