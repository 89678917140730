.tab-content {
    height: 99%;
    // .success{
    //     .Toastify__toast{
    //         background: #73b573;
    //         color:#fff;
    //         .Toastify__progress-bar{
    //           background: #587f58;
    //         }
    //     }
    // }

    // .error{
    //     .Toastify__toast{
    //         background: #c85c56;
    //         color:#fff;
    //         .Toastify__progress-bar{
    //           background: #8b4a47;
    //         }
    //     }

    // }
    .text-editor {
        // background: #edebe9;
        background: #f5f5f5;
        min-height: 96%;

        p {
            font-size: 14px;
            font-weight: bolder !important;
        }

        .rich-content {
            height: 500px;

            .ql-toolbar {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                border: none;
                margin-bottom: 0;

                .ql-bold,
                .ql-italic,
                .ql-underline,
                .ql-color-picker,
                .ql-align,
                .ql-undo,
                .ql-redo {
                    border: none;
                    padding: 5px;
                }

                .ql-clean {
                    border: none;
                    margin-top: 4px;
                    padding: 4px;
                }

                .ql-align,
                .ql-color-picker {
                    padding: 4px;
                    // padding: 1px 1px 2px 1px;
                    margin-bottom: 6px;
                }

            }

            .ql-container {
                background: #f3f7fa;
                border: none;
                display: inline;
                min-height: 100%;

                .ql-editor {
                    background: #f3f7fa;
                    padding: 5px;
                    height: 400px;

                }

                // margin:0 12px 5px;
            }

            .btn-save {
                background: #1f98df;
            }
        }
    }

    .patient-consent {
        background: #cecece;

        .card-content {
            height: 90%;
        }

        p {
            font-size: 14px;
            font-weight: bold !important;
        }

        label {
            font-size: 14px;

        }

        input[type=text] {
            font-size: 12px;
        }

        .input-group {
            line-height: .5;
        }

        input[type=file] {
            font-size: 12px;
            padding-bottom: 0;
        }

        input[type=file]::file-selector-button {
            height: 40px;
            padding: 0 4px 4px 4px;
        }

        .btn-save {
            background: #1f98df;
        }

        .supported-doc {
            font-size: 12px;
        }
    }
}

@import '../../responsive.scss';

@include md{
    

}