.account-details {
    background: #ecf4ff;
    justify-content: space-between;

    .account-info {
        background: #fff;
        border-radius: 5px;
        height: 240px;

        .acc-row {
            height: 100%;
        }

        .edit-btn {
            cursor: pointer;
        }

        .user-details {
            align-items: center;
            justify-content: center;

            img {
                display: inline;
            }

            .name-info {
                h6 {
                    font-size: 14px;
                    font-weight: bold;
                }

                p {
                    font-size: 13px;
                    text-transform: capitalize;

                }
            }
        }

        .contact-info,
        .status-info {
            h6 {
                font-size: 14px;
                font-weight: bold;
            }

            p {
                font-size: 12px;
            }
        }
    }

    .chart-info {
        background: #fff;
        border-radius: 5px;
        height: 240px;

        .apexcharts-legend {
            margin-left: auto;
            margin-right: -10px;
            max-width: 150px;
            margin-top: -10px;
            font-size: 6px;
        }

        .apexcharts-title-text {
            font-size: small;
        }

        .apexcharts-inner-graph {
            margin-top: -20px;
        }
    }

    .pie-chart-info {
        background: #fff;
        border-radius: 5px;
        height: 240px;
        flex-grow: 100;

        .apexcharts-legend {
            justify-content: space-between;
            text-align: left;
            font-size: 8px;
        }

        .apexcharts-title-text {
            font-size: small;
        }
    }

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;

        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }

    .table-data {
        background: none;
        border-radius: 10px;

        .srch-bar {
            border: 1px solid #ddd;
        }

        .search-label {
            .sr-only {
                display: none;
            }
        }

        input {
            width: 300px;
            font-size: 12px;
            border: 1 px solid #eee;
            outline: none;
            text-indent: 14px;
            font-size: 12px;
            background-color: #f4f7fa;
            border-radius: 20px;
            line-height: 1.375rem;
        }

        #search-icon {
            font-size: 0.657rem;
            position: absolute;
            text-align: center;
            line-height: 2.375rem;
            margin-top: -2px;
        }
    }

    .tabpage {
        ul {
            border: none;
            width: auto;
            margin-left: 10px;

        }

        .nav-tabs.px-2 {
            padding-left: 0 !important;
        }

        .nav-tabs.py-2 {
            padding-bottom: 0 !important;
        }

        .nav-tabs.mb-4 {
            margin-bottom: 0 !important;
        }

        .nav-tabs {
            .nav-item {
                margin-bottom: 5px;
                border: none;

                .nav-link {
                    color: #000;
                    color: #162d57;

                }

                #user-information-grid-tab-user-list {
                    background-color: #fff;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }

                #user-information-grid-tab-patient-form {
                    background-color: #fff;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding-left:5px;
                }

                #user-information-grid-tab-user-list.active,
                #user-information-grid-tab-patient-form.active {
                    background: #1f98df;
                    font-weight: bolder;
                    color: #fff;
                }


                .nav-link:hover {
                    color: #1f98df;

                }
            }
        }

        .tab-content {
            
            border-radius: 10px;
            margin: 15px 10px;

            .user-lists {
                .arrow {
                    width: 20px;
                    height: 20px;
                    margin-left: 2%;
                    margin-top: -8px;
                }
            }


            .patient-form {
                .arrow {
                    width: 20px;
                    height: 20px;
                    margin-left: 12%;
                    margin-top: -8px;
                }
            }

            .url-input {
                width: 20%;
            }

            .btn-copy {
                background: #1f98df;
                cursor: pointer;
            }

            .copied-msg {
                background: #1f98df;
                color: #fff;
            }

            .react-bootstrap-table {
                .table {
                    margin-bottom:0;
                    thead {
                        tr {
                            background: #dce5f3;
                        }
                    }

                    tbody {
                        tr {
                            background: #f3f3f4;
                            border: 1px solid #fff;

                            td {

                                .col {
                                    padding-left: 0.49rem;

                                    i {
                                        cursor: pointer;
                                    }
                                }

                                td {
                                    .spinner {
                                        height: 50vh;

                                        .css-1xdhyk6 {
                                            margin-top: 10%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .react-bootstrap-table-pagination {
                margin-bottom: 0;
            }
        }
    }
}

.modal {
    .modal-content {
        // border-radius: 10px;
        border: none;
        .modal-header {
            .modal-title {
                font-size: 18px;
                font-weight: bold;
                color: #212121;
            }

            .btn-close {
                font-size: 10px;
                font-weight: bold;
                color: #292929;
            }
        }

        .modal-body {
            .drug-name{
                
                input{
                    width: 550px;
                    padding: 8px;
                }
            }
            .input-icon {
                display: flex;
                flex-direction: row;
                border: 1px solid #eee;
                border-radius: 5px;
                background: #f4f7fa;
                align-items: center;
                overflow: hidden;
                line-height: 1;

                input {
                    outline: none;
                    border: none;
                    // background: none;
                    font-size: 1em;
                    // padding: .5em;
                    color: inherit;
                    flex: auto 1 1;
                    width: 100%;
                    // background : none;
                    background-color: transparent;
                }

                svg {
                    margin-right: 5px;

                    path {
                        position: absolute;
                        //    padding-right: 5px;
                        cursor: pointer;
                    }
                }

                input:focus {
                    outline-style: none;
                    box-shadow: none;
                }
            }

            label {
                font-size: 14px;
            }

            .css-1s2u09g-control {
                padding: 0;
            }

            .css-1hb7zxy-IndicatorsContainer {
                border: none;
            }

            .search-wrapper {
                .chip {
                    // display:block;
                    word-wrap:break-word;
                    white-space: normal;
                   
                }

                .searchBox {
                    border: none;
                }
            }


            .css-1s2u09g-control,
            textarea,
            input,
            select,
            .search-wrapper,
            .react-datepicker__input-container {
                font-size: 12px;
                border: 1px solid #eee;
                background-color: #f4f7fa;
                border-radius: 4px;
                // text-transform: capitalize;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }

            .css-1okebmr-indicatorSeparator {
                border: none;
                background: none;
            }

            .react-datepicker__input-container {
                padding: 6px;
                border-radius: 4px;
                border: 1px solid #eee;

                input {
                    width: 100%;
                }
            }

            .btn-active {
                border: 1px solid #00d100;
                background: none;
                color: #00d100;
            }
        }

        .modal-footer {
            border: none;

            .btn-save {
                background: #5A53B2;
            }

            .btn-cancel {
                background: #ff4e42;
            }
        }
    }
}