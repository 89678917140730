.amazonSearch {
    .react-bootstrap-table {
        margin-right:0px !important;
        tbody{
            tr{
                cursor: pointer;
            }
        }
    }
    .filter-icon {
        cursor: pointer;
    }

    .filter-section {
        height: 750px;
    }

    .filter-accordion {
        // min-height:50%;
        height: 68%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    // .collapse {
    //     visibility: hidden;

    .card {
        .react-datepicker__input-container {
            font-size: 12px;
            border: 1px solid #eee;
            background-color: #f4f7fa;
            border-radius: 4px;
            // text-transform: capitalize;
        }
        .react-datepicker__day--outside-month{
            visibility: hidden;
        }
        .react-datepicker__input-container {
            // padding: 6px;
            border-radius: 4px;
            border: 1px solid #eee;

            input {
                width: 100%;
            }
        }

        .btn-save{
            background:#1f98df;
        }
        .add-srch-btn{
            cursor: pointer;
        }
        .eyetype{
            background: #1F98DF;
            opacity: 0.4;
            color:#000;
        }
        .btn-active {
            background: #559c7e57;
            border:none;
            // border: 2px solid rgb(85, 156, 126);
            font-weight: 400;
            color: #559c7e;
        }

        width: 100%;

        .search-container {
            // background: #e9e7e7;
            // background: #FDFBFB;

            .btn-active {
                background: #559c7e57;
                border: 2px solid rgb(85, 156, 126);
                color: #559c7e;
            }
            .card-body{
                background: #faf8fa;
                .typeeye{
                    font-weight: 600;
                    text-transform: capitalize;
                }
                span{
                    cursor: pointer;
                   
                }
            }
            

            .card {
                .card-header {
                    background: #dcdaed;
                    color: #000;
                }

                .card-body {
                    background: #fff;
                }
            }
        }

        .accordion {
            .accordion-item {

                .accordion-header {
                    .accordion-button:not(.collapsed)::after {
                        background-image: none;
                        display: none;
                        
                    }

                    .accordion-button {
                        font-weight: 600;
                        color: #000;
                        font-family: 'Lato', 'sans-serif';

                        background-color: #f0eef5;
                        border-left: 5px solid #5A53B2;
                        $accordion-icon-active-color: "#000";
                        // .icon-rotate{
                        //     transform: rotate(-5deg);
                        // }
                        .rotate-icon {
                            transform: rotate(-40deg);
                        }
                       
                    }

                    .accordion-button.collapsed::after {
                        background-image: none;
                        display: none;
                       
                    }

                    .accordion-button.collapsed {
                        font-weight: 400;
                        background: #fff;
                        background: #faf8fa;
                        // border-bottom: 1px solid #fff;
                        font-family: 'Lato', 'sans-serif';
                        .rotate-icon {
                            transform: rotate(0deg);
                        }
                        

                        .icon-rotate {
                            transform: rotate(-90deg);
                        }
                    }

                    .accordion-button:focus {
                        outline: none;
                        border-color: none !important;
                        box-shadow: none;
                        border-bottom: none;
                    }

                    .accordion-button.active {
                        outline: none;
                        border: transparent;
                        box-shadow: none;
                       
                    }

                    .accordion-button:not(.collapsed)::after {
                        // background-image: none;
                        color: #000;
                       
                    }
                }

                .accordion-collapse {
                    .accordion-body {
                        border-left: 5px solid #5A53B2;
                         background: #faf8fa;
                         .nav-pills{
                            .nav-item{
                                .nav-link {
                                border-radius: 0px !important;
                                background: #fff;
                                color: #1F98DF;
                                }
                                .nav-link.active{
                                    background: #1F98DF;
                                    color: #fff;
                                }
                            }
                         }
                         .tab-content{
                            background: none !important;
                         }
                         .css-1s2u09g-control{
                            border-right:0px !important;
                            border-top-right-radius: 0px !important;
                            border-bottom-right-radius: 0px !important;
                         }
                        
                         .css-tlfecz-indicatorContainer{
                            padding-right:0px !important;
                         }
                        .donate-now {
                            list-style-type: none;
                            margin: 25px 0 0 0;
                            padding: 0;
                        }
                        .but-add{
                            cursor: pointer;
                            border-color: hsl(0, 0%, 80%);

                         }
                         .but-add:hover{
                            background: #5A53B2;
                            color:#fff;
                         }
                        .donate-now li {
                            float: left;
                            margin: 0 5px 0 0;
                            width: 80px;
                            height: 40px;
                            position: relative;

                        }

                        .donate-now label,
                        .donate-now input {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            cursor: pointer;
                        }

                        .donate-now input[type="radio"] {
                            opacity: 0.01;
                            // z-index: 100;
                        }

                        .donate-now input[type="radio"]:checked+label,
                        .Checked+label {
                            background: #5A53B2;
                            color: #fff;
                            border: #5A53B2;
                            font-weight: 600;
                        }
                        donate-now input[type="radio"]:focus+label{
                            background-color: pink;
                        }
                        .donate-now label {
                            padding: 5px;
                            border: 1px solid #CCC;
                            cursor: pointer;
                            // z-index: 90;
                        }

                        .donate-now label:hover {
                            background: #DDD;
                        }

                        .srch-btn {
                            background: #1F98DF;
                            border-left: 3px solid #fff;
                            cursor: pointer;
                        }

                        .tooltip {
                            position: relative;
                            display: inline-block;
                            border-bottom: 1px dotted black;
                        }

                        .tooltip .tooltiptext {
                            visibility: hidden;
                            width: 120px;
                            background-color: black;
                            color: #fff;
                            text-align: center;
                            border-radius: 6px;
                            padding: 5px 0;

                            /* Position the tooltip */
                            position: absolute;
                            z-index: 1;
                        }

                        .tooltip:hover .tooltiptext {
                            visibility: visible;
                        }

                        .rnge {


                            .input-range__slider-container {
                                .input-range__label.input-range__label--value {
                                    margin-top: -14px;

                                    // .input-range__slider{
                                    //     background: #e2e3eb;
                                    // }
                                    .input-range__label-container {
                                        // padding-left:4px;
                                        background: #5a53b2;
                                        padding: 5px;
                                        color: #fff;
                                        border-top-right-radius: 6px;
                                        border-bottom-right-radius: 6px;
                                        border-top-left-radius: 6px;
                                        border-bottom-left-radius: 2px;

                                    }
                                }
                            }
                        }

                        input {
                            border: 1px solid #e1e1e1;
                            border-radius: 4px !important;
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }

                        .container {

                            height: 100px;
                            overflow: auto;

                            .tag-list {
                                height: fit-content;
                                background: #5a53b2;
                                color: #fff;

                                span {
                                    cursor: pointer;
                                   
                                }
                            }
                        }
                    }
                    .accordion-body:focus{
                        border-bottom: none;
                    }
                }
            }
        }
    }

    // }

    // .collapse.show {
    //     visibility: visible;
    //     display: block;
    // }

    // .collapsing {
    //     position: relative;
    //     height: 0;
    //     overflow: hidden;
    //     -webkit-transition-property: height, visibility;
    //     transition-property: height, visibility;
    //     -webkit-transition-duration: 0.3s;
    //     transition-duration: 0.3s;
    //     -webkit-transition-timing-function: ease;
    //     transition-timing-function: ease;
    // }

    // .collapsing.width {
    //     -webkit-transition-property: width, visibility;
    //     transition-property: width, visibility;
    //     width: 0;
    //     height: auto;
    // }

    .react-bootstrap-table {
        padding: 0 !important;
        margin: 0 15px;
    }

    .rnge1 {
        .input-range__track {
            .input-range__slider-container {
                .input-range__label.input-range__label--value {
                    display: none;

                    .input-range__label-container {
                        display: none;
                    }
                }

            }
        }

        .input-range__slider {
            background: #fff;
            border: 3px solid #5a53b2;


        }
    }

    .rnge {

        .input-range__slider {

            border-radius: 0;
            width: 0.3rem;
            height: 1.5rem;
            margin-top: -18px;
            margin-left: 0;
        }

        .input-range__track {
            height: .7rem;
            border-radius: 0;
        }



    }

    .input-range__label--max,
    .input-range__label--min {
        .input-range__label-container {
            display: none;
        }
    }

    .modal {
        .modal-content {
            .modal-body {
                .checkbox-container {
                    border: none;

                    label.btn-chck {
                        background: #f0efef;
                        color: #000;
                        border: none;
                    }

                    .btn-check:hover+label.btn-chck {
                        box-shadow: none;
                        background: #5A53B2;
                        color: #fff;
                    }

                    .btn-check:focus+label.btn-chck {
                        box-shadow: none;
                        // background: none;
                        color: #000;
                    }

                    .btn-check:checked+label.btn-chck {
                        background: #5A53B2;
                        font-weight: bold;
                        color: #fff;
                    }


                    // .check-btn {
                    //     outline: none;
                    //     border: none;

                    //     .btn {
                    //         font-size: 13px;
                    //         width: 95px;
                    //         background: #635757;
                    //         border: 1px solid #f3f3f3;
                    //     }

                    //     .btn-check:focus+.btn {
                    //         background: #ffe3e5;
                    //         outline: none;
                    //         font-weight: bold;
                    //         box-shadow: none;
                    //     }

                    //     .btn-check:checked+.btn {
                    //         background: #ffe3e5;
                    //         font-weight: bold;
                    //     }

                    //     .btn-check+.btn {
                    //         background: #fbfbfb;
                    //     }


                    // }

                    // .btn.focus {
                    //     outline: none;
                    // }
                }

                .container {

                    height: 100px;
                    overflow: auto;

                    .tag-list {
                        height: fit-content;
                        background: #5a53b2;
                        color: #fff;

                        span {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}