.patient-details {
    background: #f5f6fb;

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .user-information{
        .user{
            .info{
                .editPatient{
                    cursor: pointer;
                }
            }
        }
        
    }
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    td{
                        .edit-icon,.del-icon{
                            color: #fff;
                            font-size: 12px;
                            padding:4px;
                            border-radius: 5px;
                        }
                        .edit-icon{
                            background: #8199bf;
                        }
                        .del-icon{
                            background: #c04444 ;
                        }
                    }
                    .col {
                        i {
                            padding:2px;
                            border-radius: 4px;
                            margin-right: 3px ;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .modal-content .modal-body select, .modal .modal-content .modal-body .react-datepicker__input-container{
        // background: none ;
        border-radius: none;
        font-size: 14px;
        input{
            border:none;
        }
    }
    .modal-content .modal-body select, .modal .modal-content .modal-body {
        .react-datepicker__month-select,.react-datepicker__year-select{
            margin-top: -10px;
            background-color:#f0f0f0;
        }
    }
    .react-datepicker__month{
        .react-datepicker__week{
            .react-datepicker__day--outside-month{
                visibility: hidden;
            }
        }
    }
    .react-datepicker__current-month{
        display:none;
    }
    .user{
        border-radius: 10px;
        .user-info {
           
            p{
            color:#838383;

        }
    }
        .other-info
        { 
            .information{
                text-transform: capitalize;
            }
            label{
            color:#838383;
            font-size: 14px;
        }
         p{
            color: #000;
            font-size: 16px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;
            li{
                align-items: center;  
                background: #fff2f3; 
                border-radius: 2px;   
                color: #6b6869;   
                list-style: none;
                margin: 5px;
                padding: 5px;
                button{
        
                    align-items: center;
                    color: #6b6869; 
                    background: none;
                    border: none;
                    cursor: pointer;
                    height: 15px;
                    width: 15px;
                    font-size: 12px;
                    border-radius: 50%;
                
                    display: inline-flex;
                
                    justify-content: center;
                
                    margin-left: 8px;
                
                    transform: rotate(45deg);
                
                  }
        
            
              }
             
            
            
          }
         
        
        .rti--container{
            border:1px solid #f3f3f3;
            padding:10px;
           
            .rti--tag{
                margin: 5px;
                color: #6b6869;  
                background: #fff2f3; 
                    padding: 5px;
                align-items: center;
               
                span{
                  
                    padding:0;
                    margin: 0 5px 2px 5px;
                  
                }
                button{
                    margin:5px;
                    font-size: 10px;
                }
            }
            .rti--input{
                background: none;
                font-size: 14px;
                flex-grow: 1;
            }
        }

    }
        
    

    }
        
    }


    


@import "../responsive.scss";

@include mob-hor-max {
    .topic {
        .btn {
            margin-right: 12px;
        }
    }
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    .col {
                        i {
                            margin-right: 1px !important ;
                        }
                    }
                }
            }
        }
    }
}

@include tab {
    .react-bootstrap-table {
        .table {
            tr {
                td {
                    .col {
                        i {
                            margin-right: 1px !important;
                        }
                    }
                }
            }
        }
    }
}

// @include xl-min{
//     .react-bootstrap-table{
//         .table{
//             tr{
//                 td{
//                     .col{
//                         i{
//                             margin-right: 3px !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
