.medicine {
    background: #f5f6fb;

    .heading {
        h5 {
            color: #252525;
        }
    }

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;

        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }

    .additional-section {
        display: none;
    }

    .additional-section.additional {
        display: block;
    }

    .react-bootstrap-table {
        .table {

            tbody {
                tr {
                    td {
                       .__react_component_tooltip{
                        width: auto;
                       }
                        .del-btn,.edit-btn{
                            color:#fff;
                            padding: 3px;
                            border-radius: 5px;
                        }
                        .edit-btn{
                            background: #3f9b58;
                            // margin-bottom: 2px;
                        }
                        .del-btn{
                            background: #f44336;
                        }
                        p {
                            cursor: pointer;
                            span {
                                background: #1f98df;
                                font-size: 12px;
                                color: #fff;
                            }
                        }

                        .action-icons {
                            // padding-left: 0.49rem;
                            i {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .side-content {
        height:60vh;
        overflow-y: scroll;
        .header {
            min-height: 65px;
            background: #e1e1e1;
            color: #000;
            border-radius: 5px;
        }

        .content-body {
            font-size: 14px;
            background: #fff;
            height: 65%;
            border-bottom: solid 1px #e1e1e1;

            .noData {
                color: lightgray;
            }

            .url {
                cursor: pointer;

            }

            .url:hover {
                text-decoration: underline;
            }
        }
    }

}

@import '../responsive.scss';
@include lg{
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    td {  
                        .edit-btn{
                            background: #3f9b58;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }


}

@include tab {
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    td {  
                        .edit-btn{
                            background: #3f9b58;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }

}



