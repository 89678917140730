.diagnosis {
    background: #f5f6fb;
    .heading {
        h5 {
            color: #252525;
        }
    }

    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .react-bootstrap-table{
        .table{
            tbody{
                tr{
                    td{
                        .col{
                            padding-left: 0.49rem;
                            i{
                                cursor:pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    // .table-data {
    //     background: none;
    //     border-radius: 10px;

    //     .search-label {
    //         .sr-only {
    //             display: none;
    //         }
    //     }

    //     input {
    //         width: 300px;
    //         font-size: 12px;
    //         border: none;
    //         outline: none;
    //         // height: 40px;
    //         // max-width: 300px;
    //         text-indent: 14px;
    //         font-size: 12px;
    //         border-radius: 20px;
    //         line-height: 1.375rem;
    //         // position: relative;
    //     }
    //     #search-icon {
    //         font-size: 0.657rem;
    //         position: absolute;
    //         text-align: center;
    //         line-height: 2.375rem;
    //     }
    //     .react-bootstrap-table {
    //         border-radius: 10px;
    //         background-color: #fff;
    //         .table {
    //             background: #fff;
    //             border-radius: 10px;
    //             table-layout: auto;
    //             overflow-x: auto;
    //             thead {
    //                 tr {
    //                     outline: none;
    //                     border: none;
    //                     background: #e1e1e1;
    //                     font-size: 14px;
    //                     font-family: "Lato", sans-serif;
    //                     color: #162d57;
                       
    //                     th {
    //                         border-color: transparent;
    //                         text-align: left;
    //                         padding-left: 12px;
    //                         .order-4 {
    //                             margin-left: 5px;
    //                         }
    //                         .order-4::before {
    //                             content: "\25b4" !important;
    //                         }
    //                         .order-4::after {
    //                             content: "\25be" !important;
    //                         }
    //                         .caret-4-asc::after {
    //                             content: "\25be";
    //                             margin-top: -10px;
    //                         }
    //                         .caret-4-desc::before {
    //                             content: "\25b4";
    //                         }
    //                         .caret-4-desc:after {
    //                             content: "\25be" !important;
    //                         }
    //                         .caret-4-asc:before {
    //                             content: "\25b4" !important;
    //                         }
    //                     }
    //                 }
    //                 tr:first-child th:last-child {
    //                     border-top-right-radius: 10px;
    //                 }
    //                 tr:first-child th:first-child {
    //                     border-top-left-radius: 10px;
    //                 }
    //             }
    //             tbody {
    //                 border-top: none;
    //                 tr {
                       
    //                     color: #596883;
    //                     font-size: 12px;
    //                     text-align: left;
    //                     border: 1px solid #f4f4f4;
                        
    //                     td {
    //                         padding: 0.8rem;
    //                         vertical-align: middle;
    //                         border: none;
    //                         .col{
    //                            padding-left: 0.49rem;
    //                             i{
    //                                 cursor: pointer;
    //                             }
    //                         }
    //                     }
    //                 }

    //                 tr:last-child td:first-child {
    //                     border-bottom-right-radius: 10px;
    //                 }
    //                 tr:last-child td:last-child {
    //                     border-bottom-right-radius: 10px;
    //                 }
    //             }
    //         }
    //     }
    //     .react-bootstrap-table-pagination {
    //         justify-content: flex-end;
    //         align-items: center;
    //         flex-direction: row-reverse;
    //         margin:20px 0;
    //         .col-md-6 {
    //             max-width: 80px;
    //             padding: 0;
    //             margin: 0 0 0 5px;
    //             align-items: center;
    //             .react-bs-table-sizePerPage-dropdown {
    //                 height:20px;
                   
    //                 .btn {
    //                     line-height:15px;
    //                     text-align: center;
    //                     width: 100%;
    //                     background: #fff;
    //                     border: 1px solid lightgray;
    //                     color: #333;
    //                     // padding:5px 10px;
    //                 }

    //                 .dropdown-menu {
    //                     margin: 10px;
    //                     min-width: 50px !important;
    //                 }
    //             }
    //         }
    //         .react-bootstrap-table-pagination-list {
    //             margin: 10px 0 10px auto;
    //             align-items: center;
    //             padding: 0;
    //             .react-bootstrap-table-page-btns-ul {
    //                 margin-bottom: 0;
    //                 line-height:15px;
    //                 .page-item {
    //                     outline: none;

    //                     .page-link {
    //                         margin-left: 2px;
    //                         border-radius: 5px;
    //                         font-size: 14px;
    //                         outline: none;
    //                         color: #252525;
    //                         background:#fff;
    //                     }
    //                 }
    //                 .page-item.active {
    //                     .page-link {
    //                         background: #1f98df;
    //                         outline: none;
    //                         color: #fff;
    //                         border: 1px solid #1f98df;
    //                     }

    //                     // background: #1f98df;
    //                 }
    //             }
    //         }
    //     }
    // }
}
