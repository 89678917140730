.view-request {
    background: #f5f6fb;
    .heading {
        h5 {
            color: #252525;
        }
    }
    #search-icon{
        font-size: 12px !important;
        line-height: 2rem !important;
    }
    .btn-loading{
        background: #FF8C01;
    }
    .btn-download{
        background: #006B3E;
    }
    .btn-create {
        background: #1f98df;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        align-items: center;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .btn-export{
        border-radius: 20px;
        background: #1f98df;
        font-size: 13px;
    }
    .react-datepicker__input-container {
        font-size: 12px;
        border: 1px solid #eee;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        //  height: 20px;
        input{
            border:none !important;
            background: transparent;
        }
    }
    
    .react-datepicker__month-select,
    .react-datepicker__year-select {
        margin-top: -10px;
        background-color: #f0f0f0;
    }
    .react-datepicker__month {
        .react-datepicker__week {
            .react-datepicker__day--outside-month {
                visibility: hidden;
            }
        }
    }
    .react-datepicker__current-month {
        display: none;
    }
    .react-datepicker__year-read-view--down-arrow {
        display: none;
    }
    .react-bootstrap-table {
        .table {
            tbody {
                tr {
                    cursor: pointer;
                    td{
                        p{
                            margin-bottom:0;
                        }
                        .spinner{
                            height: 50vh;
                            .css-1xdhyk6{
                                margin-top:10%;
                            }
                        }
                    }
                }
            }
        }
    }   
}
