// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@font-face {
    font-family: "Trade Gothic Next SR Pro Bd Cm";
    src: url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.eot");
    src: local("Trade Gothic Next Soft Rounded Pro Bold Compressed"), local("TradeGothicNextSRPro-BdCm"),
        url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.eot?#iefix") format("embedded-opentype"),
        url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.woff2") format("woff2"),
        url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.woff") format("woff"),
        url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.ttf") format("truetype"),
        url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.svg#TradeGothicNextSRPro-BdCm") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    border: 0;
    outline: none;
    padding: 0;
    font-family: "Lato", "sans-serif";
    box-sizing: border-box;
}

html,
body,
#root,
.App {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: #f5f6fb;
}

html,
body {
    font-family: "Lato", sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eaeaea;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #7A6EFE;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// heading

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Trade Gothic Next SR Pro Bd Cm";
    font-weight: bold;
    color: #000;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-family: "Lato";
    font-weight: bold;
    font-size: 24px;
}

h5,
.h5 {
    font-size: 20px;
    font-family: "Lato", "sans-serif";
}
h6 {
    font-size: 14px;
    font-family: "Lato", "sans-serif";
}

p {
    font-family: "Lato", "sans-serif";
    font-size: 12px;
    color: #4d596c;
    margin-bottom: 6px;
}
.nav-link {
    font-family: "Lato", "sans-serif";
}
label {
    font-family: "Lato", "sans-serif";
}
button{
    font-family:"Lato", "sans-serif" ;
}
.btn,select,Select,input,p{
    font-family:"Lato", "sans-serif" ;
}
button:focus,
select:focus {
    outline: none !important;
}
.btn:focus {
    outline: none !important;
}
.btn:focus,
.btn:hover,
.btn:active {
    box-shadow: unset !important;
}
select:focus,
select:hover,
select:active {
    box-shadow: unset !important;
}
input:focus {
    outline-style: none;
    box-shadow: none;
}
.invalid-feedback,.invalid-msg {
    font-size: 16px !important;

    text-align: left;
}
.arr-msg {
    color: #d8000c;
    font-family: "Lato", "sans-serif";
    background-color: #ffd2d2;
    text-align: left;
    padding: 3px 0 3px 10px;
    border-radius: 3px;
    font-size: 14px;
    margin: 7px 0px 7px 0px;
    i {
        font-size: 12px;
    }
}

.err-msg {
    color: #d8000c;
    font-family: "Lato", "sans-serif";
    background-color: #ffd2d2;
    padding: 10px 0 10px 0px;
    border-radius: 3px;
    font-size: 15px;
    margin: 7px 0px 7px 0px;
    text-align: center;
    font-weight: 600;
}
.success-msg {

    font-family: "Lato", "sans-serif";
    color: green;
    background-color: #caeeb1;
    padding: 10px 0 10px 0px;
    border-radius: 3px;
    font-size: 15px;
    margin: 7px 0px 7px 0px;
    text-align: center;
    font-weight: 600;
}
.err-message
{
    font-family: "Lato", "sans-serif";
    width: 60%;
    margin: 0 230px 0 230px;
}
.required-field{
    color:#d8000c;
    font-family: "Lato", "sans-serif";
}

// Rich Editor

.rdw-editor-main {
    border: solid 1px #dee2e6 !important;
    min-height: 250px !important;
    max-height: 250px !important;
}
.public-DraftStyleDefault-block {
    margin: 0.5em 0.3em !important;
}
#editRichText
{
    .modal-content
    {
        width: 750px;
    }
}
#editRichTextInterpretation
{
    .modal-content
    {
        width: 750px;
    }
}
#editRichTextFeedback
{
    .modal-content
    {
        width: 750px;
    }
}
.preview p{
    font-family: "Lato", "sans-serif";
    font-size: 14px !important;
}

.next
{
    background: #1f98df;
    border: none;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "Lato", "sans-serif";
}
.back
{
    background: #fff;
    border: 1px solid #1f98df;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "Lato", "sans-serif";
}
.pre-next-btn
{
    padding: 12px;
    background: white;
    border-radius: 10px;
    font-family: "Lato", "sans-serif";
}
.down-link
{
    font-family: 'Lato',;
    color: blue;
    text-decoration: underline;
    background: none;
    font-family: "Lato", "sans-serif";
}
.drop-container {
    background: #fff;
    border-radius: 6px;
    height: 100px;
    width: 100%;
    box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #c0c4c7;
  }
  .upload-button {
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    color: #5754a3;
  }
  .upload-button input {
    display: none;
  }
  .dropzone { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    border-radius: 5px;
    background: white;
    margin: 10px 0;
  }
  .dropzone.hovering {
      border: 2px solid #f16624;
      color: #dadada !important;
  }



@import "./responsive.scss";
